<template>
<div class="googleplaces-container">
    <div class="top">

        <div class="fields">

            <div class="field" v-if="googlePlace.localized_name">
                <p>POI</p>
                <vs-input type="text" v-model="googlePlace.localized_name" disabled />
            </div>

            <div class="field">
                <p>Nome</p>
                <vs-input type="text" v-model="googlePlace.display_name" disabled />
            </div>

            <div class="field">
                <p>Indirizzo</p>
                <vs-input type="text" v-model="googlePlace.formatted_address" disabled />
            </div>

            <div class="field">
                <p>Categoria</p>
                <vs-input type="text" v-model="googlePlace.primary_type_display_name" disabled />
            </div>

            <vs-row direction="column" style="gap: 20px; margin-top: 20px; width: 100%;" justify="center">
                <vs-card v-for="(photo, index) in photos" :key="index" style="width: 100%;">

                    <template #img>
                        <img :src="photo.url" style="width: 100%;" @load="loadingImages--" />
                    </template>
                    <template #interactions>
                        <vs-button primary icon @click="linkToPOI(photo)" :disabled="googlePlace.poi == null">
                            <i class='bx bx-link'></i> Associa al POI
                        </vs-button>
                        <vs-button class="btn-chat" shadow primary @click="downloadPhoto(photo.blob)">
                            <i class='bx bx-download'></i> Download
                        </vs-button>
                    </template>
                </vs-card>
            </vs-row>

        </div>
    </div>

</div>
</template>

<script>
import {
    apiCall,
} from '../../utils/ApiMiddleware';
export default {
    name: "googleplacesEl",
    props: {
        googlePlace: {
            type: Object,
            required: true
        },
    },
    components: {},
    data() {
        return {
            photos: [],
            loadingImages: 0,
            loader: null
        }
    },

    async mounted() {
        if (this.googlePlace.photo_names != null && this.googlePlace.photo_names.length > 0) {
            for (const photoName of this.googlePlace.photo_names) {
                this.getPhoto(photoName);
            }
        }
    },

    methods: {
        async getPhoto(name) {
            const response = await apiCall('GET', `/backoffice/picture`, {
                name: name,
                max_width: 800,
                max_height: 800
            });
            if (response.status == 200 && response.data.uri) {
                this.loadingImages++;
                // fetch uri with "fetch" to get blob
                const blob = await fetch(response.data.uri).then(r => r.blob()); // with axios there is something wrong with withCredentials and gives CORS error
                const url = window.URL.createObjectURL(blob);
                this.photos.push({
                    blob,
                    url,
                });
            }
        },

        downloadPhoto(blob) {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;

            // generate name from display_name (replace spaces with -) and normalize it to allow only alphanumeric characters
            const name = this.googlePlace.display_name.replace(/ /g, '-').replace(/[^a-zA-Z0-9-]/g, '');

            a.download = `${name}-${new Date().getTime()}.jpg`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        },

        async linkToPOI(photo) {
            // getSignedUploadURL
            const loading = this.$vs.loading({
                shape: 'square',
                text: 'Linking photo to POI...',
            });
            try {
                const urlResult = await apiCall('GET', `/files/getSignedUploadURL`, {
                    size: photo.blob.size,
                });

                if (urlResult.status == 200 && urlResult.data.url && urlResult.data.name) {
                    // get poi details
                    // https://tourial.it.wised.it/api/tourial/v1/backoffice/pois?id=asd
                    const poiResult = await apiCall('GET', `/backoffice/pois`, {
                        id: this.googlePlace.poi.id
                    });

                    console.log(poiResult);
                    let poi;
                    if (poiResult.status == 200 && poiResult.data.data && poiResult.data.data.length) {
                        poi = poiResult.data.data[0];
                        if (!poi.pictures) {
                            poi.pictures = [];
                        }

                        poi.pictures.push({
                            name: urlResult.data.name,
                            description: 'Google Places',
                        });
                    } else {
                        throw new Error('Error getting POI details');
                    }

                    // PATCH /backoffice/pois/{id}
                    const patchResult = await apiCall('PATCH', `/backoffice/pois/${this.googlePlace.poi.id}`, {
                        ...poi,
                        point: {
                            x: poi.longitude,
                            y: poi.latitude
                        }
                    });

                    if (patchResult.status == 200) {
                        // upload image to S3
                        const uploadResult = await fetch(urlResult.data.url, {
                            method: 'PUT',
                            body: photo.blob,
                            credentials: 'omit',
                        });

                        if (uploadResult.status == 200) {
                            this.$vs.notification({
                                color: 'success',
                                position: 'top-right',
                                title: 'Success',
                                text: 'Image uploaded successfully'
                            });
                        } else {
                            throw new Error('Error uploading image to S3');
                        }
                    } else {
                        throw new Error('Error patching POI');
                    }
                } else {
                    throw new Error('Error getting signed upload URL');
                }
            } catch (error) {
                this.$vs.notification({
                    color: 'danger',
                    position: 'top-right',
                    title: 'Error',
                    text: error.message
                });
            }

            loading.close();
        }
    },

    watch: {
        loadingImages(n) {
            if (n > 0 && this.loader == null) {
                this.loader = this.$vs.loading({
                    shape: 'square',
                    text: 'Loading images...',
                });
            } else if (n == 0 && this.loader != null) {
                this.loader.close();
                this.loader = null;
            }
        }
    }
}
</script>

<style scoped>
.header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

}

.top {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.fields {
    display: flex;
    flex-direction: column;
}

.field {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    border-bottom: 1px solid var(--grey-75);
}

.field p {
    width: 35%;
    max-width: 35%;
}

.googleplaces-container {

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    height: calc(100% - 75px);

    justify-content: space-between;
}

/* MOBILE */
@media (max-width: 600px) {
    .googleplaces-container {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        box-sizing: border-box;

    }

}

textarea {
    border-radius: 5px;
    border: 1px solid var(--grey-75);
    height: 150px;
    max-height: 150px;
    padding: 10px;
    font-family: Poppins;
}
</style><style>
.googleplaces-container .field .vs-input {
    background: transparent !important;
    font-size: 1rem;
}

.googleplaces-container .field .vs-select__input {
    background: transparent !important;
    font-size: 1rem;
}

.googleplaces-container .field input::-webkit-date-and-time-value {
    text-align: left !important;
    font-size: 1rem;
}
</style>

<style scoped>
.googleplaces-container .vs-card {
    max-width: unset!important;
}
</style>